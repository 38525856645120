import React from "react"
import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Navbar from "../components/navbar"
import Iframe from "react-iframe"
import MailchimpForm from "../components/mailchimp-form/mailchimp-form"
import Layout from "../components/layout"
import "./contacts.scss"
import SEO from "../components/seo"

const ContactsPage = () => (
  <Layout>
    <Helmet>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
      />
    </Helmet>
    <SEO title="Контакты" />

    <Navbar />
    <section className="contacts-section-telis">
      <Container fluid>
        <Row>
          <Col
            xl={6}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="align-self-center my-5 pt-5"
          >
            <h2 className="contacts-heading">
              Мы в <br />
              Санкт-Петербурге <br />
              Напишите нам!
            </h2>{" "}
            <br />
            <u className="contacts-heading">sales@telisit.ru</u>
            <br />
            <span className="text-muted my-5 d-block">
              &mdash; Или &mdash;
            </span>{" "}
            <br />
            <MailchimpForm />
          </Col>
          <Col xl={6} lg={12} md={12} sm={12} xs={12} className="map-container">
            <Iframe
              url="https://snazzymaps.com/embed/127556"
              width="100%"
              className="map-iframe"
              height="100%"
            />
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default ContactsPage
