import addToMailchimp from "gatsby-plugin-mailchimp"
import React, { useState } from "react"
import * as styles from "./EmailListForm.module.scss"

const MailchimpForm: React.FunctionComponent<{}> = () => {
  const [email, setEmail] = useState("")

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    addToMailchimp(email)
      .then(data => {
        alert(data.result)
      })
      .catch((error: Error) => {
        // Errors in here are client side
        // Mailchimp always returns a 200
      })
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value)
  }

  return (
    <form onSubmit={handleSubmit} className={styles.EmailListForm}>
      <h2>Оставьте свой Email и мы свяжемся с Вами в ближайшее время</h2>
      <div className={styles.Wrapper}>
        <input
          placeholder="Email"
          name="email"
          type="text"
          onChange={handleEmailChange}
        />
        <button type="submit">Отправить</button>
      </div>
      <p className="text-dark mb-0 mt-2" style={{ fontSize: 10 }}>
        Нажимая "Отправить" Вы соглашаетесь на обработку персональных данных
      </p>
    </form>
  )
}

export default MailchimpForm
